import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Auth } from 'aws-amplify';
import { NavItem, SingleNavItem } from './components';
import LogoWhite from '../../../../resources/logo-white.png'; 
import LogoBlack from '../../../../resources/logo-black.png'; 
import { useAppContext } from 'lib/contextLib';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useHistory } from 'react-router-dom';


const Topbar = ({ onSidebarOpen, colorInvert = false }) => {
  const history = useHistory();
  const location = useLocation();
  const { user, setUser } = useAppContext();
  
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  async function handleLogout() {
    await Auth.signOut();
    setUser(null);
    history.push('/');
  }

  async function trade() {
    history.push('/trades');  
  }

  const subMenus = {
    accounts: [
      {
        title: 'My Portfolio',
        onClick: trade
      },
      {
        title: 'Sign Out',
        onClick: handleLogout,
      },
    ],
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="Pytho"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={'img'}
          src={theme.palette.mode === 'dark' ? LogoWhite : LogoBlack}
          height={1}
          width={theme.palette.mode === 'dark' ? 40 : 1}
        />
      </Box>
      {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box marginLeft={4}>
          <SingleNavItem
            title={'Explore Listings'}
            id={'invite'}
            path='/listings'
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <SingleNavItem
            title={'Invite Friends'}
            id={'invite'}
            path='/invite-a-friend'
            colorInvert={colorInvert}
          />
        </Box> 
        <Box marginLeft={4}>
          <SingleNavItem
            title={'About Us'}
            id={'invite'}
            path='/about'
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <SingleNavItem
            title={'Blog'}
            id={'blog'}
            path='/blog'
            colorInvert={colorInvert}
            reload={true}
          />
        </Box>
        <Box marginLeft={4}>
          <SingleNavItem
            title={'FAQ'}
            id={'faq'}
            path='/faq'
            colorInvert={colorInvert}
            reload={true}
          />
        </Box>
       
        <Box marginLeft={4}>
          {!user ? (
          <Button
            variant="outlined"
            color="primary"
            component={Link} to='/signup'
            size="large"
          >
            Get Started
          </Button>
          ) : (
          <NavItem 
            title={user.firstName} 
            items={subMenus.accounts} 
            id='account' />
          )}
        </Box>
      </Box> */}
      {/* <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}> */}
      <Box sx={{ display: { xs: user ? 'block' : 'none'} }} alignItems={'center'}>
        {user && !isMd && <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>}
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;