import React from "react";
import { useState } from "react";
import config from '../../config';
import ms from 'milsymbol';
import { Box, Typography } from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import GoogleMap from 'google-maps-react-markers'


function createMapOptions() {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      gestureHandling: 'cooperative',
      mapTypeId: 'hybrid',
      layerTypes: ['TrafficLayer', 'TransitLayer'],
    //   styles: [
    //     {
    //         "featureType": "all",
    //         "elementType": "geometry.fill",
    //         "stylers": [
    //             {
    //                 "weight": "2.00"
    //             }
    //         ]
    //     },
    //     // {
    //     //     "featureType": "all",
    //     //     "elementType": "geometry.stroke",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#9c9c9c"
    //     //         }
    //     //     ]
    //     // },
    //     {
    //         "featureType": "all",
    //         "elementType": "labels.text",
    //         "stylers": [
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     // {
    //     //     "featureType": "landscape",
    //     //     "elementType": "all",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#f2f2f2"
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "landscape",
    //     //     "elementType": "geometry.fill",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#ffffff"
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "landscape.man_made",
    //     //     "elementType": "geometry.fill",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#ffffff"
    //     //         }
    //     //     ]
    //     // },
    //     {
    //         "featureType": "poi",
    //         "elementType": "all",
    //         "stylers": [
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     // {
    //     //     "featureType": "road",
    //     //     "elementType": "all",
    //     //     "stylers": [
    //     //         {
    //     //             "saturation": -100
    //     //         },
    //     //         {
    //     //             "lightness": 45
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "road",
    //     //     "elementType": "geometry.fill",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#eeeeee"
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "road",
    //     //     "elementType": "labels.text.fill",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#7b7b7b"
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     "featureType": "road",
    //     //     "elementType": "labels.text.stroke",
    //     //     "stylers": [
    //     //         {
    //     //             "color": "#ffffff"
    //     //         }
    //     //     ]
    //     // },
    //     {
    //         "featureType": "road.highway",
    //         "elementType": "all",
    //         "stylers": [
    //             {
    //                 "visibility": "simplified"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "road.arterial",
    //         "elementType": "labels.icon",
    //         "stylers": [
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "transit",
    //         "elementType": "all",
    //         "stylers": [
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "water",
    //         "elementType": "all",
    //         "stylers": [
    //             {
    //                 "color": "#46bcec"
    //             },
    //             {
    //                 "visibility": "on"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "water",
    //         "elementType": "geometry.fill",
    //         "stylers": [
    //             {
    //                 "color": "#c8d7d4"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "water",
    //         "elementType": "labels.text.fill",
    //         "stylers": [
    //             {
    //                 "color": "#070707"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "water",
    //         "elementType": "labels.text.stroke",
    //         "stylers": [
    //             {
    //                 "color": "#ffffff"
    //             }
    //         ]
    //     }
    //   ],
    };
  }

  
export default function SimpleMap({ height, map }){
  const defaultProps = {
    zoom: 8
  };
  // //S*G*SPT---****X
  // //S*A*WMAS--
  // //SFG-UCI----D
  // //sfgpewrh--mt
  // //SFG*EVATH-
  // //SHG*UCFM--
  // const symbol = new ms.Symbol("SHG*UCFM--", {
  //   size: 35,
  //   //quantity: 200,
  //   staffComments: "for reinforcements".toUpperCase(),
  //   //additionalInformation: "added support for JJ".toUpperCase(),
  //   //direction: (750 * 360) / 6400,
  //   //  type: "machine gun".toUpperCase(),
  //   //dtg: "30140000ZSEP97",
  //   //location: "0900000.0E570306.0N",
  //   infoColor: '#FFFFFF',
  // }).asSVG();
  
  const latLngs = new Map()

  function locationKey(index, day) {
    return JSON.stringify({ index, day })
  }

  if (map.units) { 
    map.units.map((unit, index) => {
    if (unit.daily_positions && unit.daily_positions.length > 0) {
      unit.daily_positions.map((pos, _) => latLngs.set(locationKey(index, pos.day), {
        lat: pos.lat,
        lng: pos.lng,}))
    } else {
      latLngs.set(locationKey(index, 0), {
        lat: unit.lat,
        lng: unit.lng,})
    }})
    
    console.log(latLngs)
  }
  const [latLngMap, setLatLngMap] = useState(latLngs)
  const [mapKey, setMapKey] = useState(0)

  const dragEnd = (index, shouldRefresh) => (e, { latLng }) => {
    setLatLngMap(previousMap => new Map(
      previousMap.set(index, {
        lat: latLng.lat,
        lng: latLng.lng 
      }))
    )
    
    if (shouldRefresh) {
      setMapKey((mapKey + 1) % 2)
    }
  }

  const apiIsLoaded = (gmap, maps) => {
    const directionsService = new google.maps.DirectionsService()

    // unit routes
    map.units && map.units.map((unit, index) => {
      if (unit.daily_positions && unit.daily_positions.length > 1) {
        for (let i = 0; i < unit.daily_positions.length-1; i++) {
          const startDay =  unit.daily_positions[i].day
          const endDay =  unit.daily_positions[i+1].day
          const activity = unit.daily_positions[i].activity
          if (activity) {
            i = i + 1
          }
          directionsService.route({
            origin: latLngMap.get(locationKey(index, startDay)),
            destination: latLngMap.get(locationKey(index, endDay)),
            travelMode: google.maps.TravelMode.DRIVING
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              const symbols = [
                {
                  lineIcons: [{
                    icon: {
                      path: "M -1 -1 L 1 0 M -1 1 L 1 0",
                      strokeOpacity: 1,
                      scale: 3,
                    },
                    repeat: "6px",
                  },
                  {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                      strokeOpacity: 1,
                      scale: 3,
                    },
                    offset: "2px",
                  }],
                  strokeWeight: 0.0
                },
                {
                  lineIcons: [{
                    icon: {
                      path: "M -2 0 L 2 0",
                      strokeOpacity: 1,
                      scale: 3,
                    },
                    offset: "97%",
                  },
                  {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                      strokeOpacity: 1,
                      scale: 3,
                    },
                    offset: "20%",
                  }],
                  strokeWeight: 3.0
                },
              ]

              if (activity && (activity === 'fix' || activity === 'penetrate')) {
              
                var lineSymbol = symbols[0]
                if (activity === 'penetrate') {
                  lineSymbol = symbols[1]
                }

                var polyline = new google.maps.Polyline({
                  strokeColor: 'red',
                  strokeWeight: lineSymbol.strokeWeight,
                  fillColor: 'red',
                  fillOpacity: 1.0,
                  zIndex: 15,
                  icons: lineSymbol.lineIcons,
                }) 
              } else {
                var polyline = new google.maps.Polyline({
                  strokeColor: 'red',
                  strokeWeight: 3,
                  fillColor: 'red',
                  fillOpacity: 1.0,
                  zIndex: 15,
                });
              }
              var rendererOptions = {
                map: gmap,
                suppressMarkers : true,
                polylineOptions: polyline
              }
              const directionsRenderer = new google.maps.DirectionsRenderer(rendererOptions)
        
              directionsRenderer.setDirections(result);
             
            } else {
              console.error(`error fetching directions ${JSON.stringify(result)}`);
            }
          })
          
        }
      }
    })

    // avenue of approaches
    map.avenues_of_approaches && map.avenues_of_approaches.map((avenue, index) => {
      directionsService.route({
        origin: avenue.start,
        destination: avenue.end,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          var polyline = new google.maps.Polyline({
            strokeColor: '#F1EBD3',
            strokeOpacity: 1,
            strokeWeight: 14,
            zIndex: 1,
          });
          var rendererOptions = {
            map: gmap,
            suppressMarkers : true,
            polylineOptions: polyline
          }
          const directionsRenderer = new google.maps.DirectionsRenderer(rendererOptions)
    
          directionsRenderer.setDirections(result);
          
          var polylineBorder = new google.maps.Polyline({
            strokeColor: 'yellow',
            strokeOpacity: 1.0,
            strokeWeight: 18,
            zIndex: 0,
        
          })
          var rendererOptions = {
            map: gmap,
            suppressMarkers : true,
            polylineOptions: polylineBorder
          }
          const directionsRendererBorder = new google.maps.DirectionsRenderer(rendererOptions)
    
          directionsRendererBorder.setDirections(result);
          
          const lineSymbol = {
            path: google.maps.SymbolPath.CIRCLE,
          }

          
          var polylineArrow = new google.maps.Polyline({
            strokeColor: 'yellow',
            //strokeOpacity: 1.0,
            strokeWeight: 3,
            fillColor: 'yellow',
            fillOpacity: 1.0,
            zIndex: 5,
            //editable: true,
            icons: [{
              icon: lineSymbol,
              offset: "100%",
            },
              // {
              //   icon: milSymbol,
              //   offset: '0',
              //   repeat: '20px'
              // }
            ]
          })
            
          var rendererOptions = {
            map: gmap,
            suppressMarkers : true,
            polylineOptions: polylineArrow
          }
          const directionsRendererArrow = new google.maps.DirectionsRenderer(rendererOptions)
    
          directionsRendererArrow.setDirections(result)
        } else {
          console.error(`error fetching directions ${JSON.stringify(result)}`);
        }
      })
    })

    // phase lines
    map.phase_lines && map.phase_lines.map((phase_line, index) => {
      directionsService.route({
        origin: phase_line.west,
        destination: phase_line.east,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          var polyline = new google.maps.Polyline({
            strokeColor: 'black',
            strokeOpacity: 1,
            strokeWeight: 2,
            zIndex: 1,
          });
          var rendererOptions = {
            map: gmap,
            suppressMarkers : true,
            polylineOptions: polyline
          }
          const directionsRenderer = new google.maps.DirectionsRenderer(rendererOptions)
    
          directionsRenderer.setDirections(result);

        } else {
          console.error(`error fetching directions ${JSON.stringify(result)}`);
        }
      })
    })
    
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: height, width: '100%' }} id={map.id}>
      <GoogleMap
        key={mapKey.toString()}
        apiKey={config.GOOGLE_MAP_API_KEY}
        defaultCenter={map.center}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}        
      >
        {map.units && map.units.map((unit, index) => {
          var sidc = unit.sidc
          if (unit.type === 'hostile') {
            sidc = sidc[0] + 'H' + sidc.substring(2)
          } else if  (unit.type === 'friendly') {
            sidc = sidc[0] + 'F' + sidc.substring(2)
          }

          if (unit.daily_positions && unit.daily_positions.length > 0) {
            return unit.daily_positions.map((pos, _) => { 
              if (!pos.activity) { return (<Box
                lat={latLngMap.get(locationKey(index, pos.day)).lat}
                lng={latLngMap.get(locationKey(index, pos.day)).lng}
                draggable={true}
                onDragEnd={dragEnd(locationKey(index, pos.day), true)}
                sx={{
                  position: "relative",
                  transform: "translate(-22px, -50%)"
                  //transform: "translate(-22px, -28px)"
                }}
                dangerouslySetInnerHTML={{ __html: new ms.Symbol(sidc, {
                  size: 20,
                  staffComments: unit.name.toUpperCase(),
                  additionalInformation: "D+" + pos.day,
                  infoColor: (sidc[1] == 'H') ? '#FF0000' : '#0000FF',
                }).asSVG()}}
              />)} else {
                return (<Box
                  lat={latLngMap.get(locationKey(index, pos.day)).lat}
                  lng={latLngMap.get(locationKey(index, pos.day)).lng}
                  draggable={true}
                  onDragEnd={dragEnd(locationKey(index, pos.day), true)}
                  sx={{
                    position: "relative",
                    //transform: "translate(-22px, -50%)"
                    //transform: "translate(-22px, -28px)"
                  }}
                  dangerouslySetInnerHTML={{ __html: new ms.Symbol(sidc, {
                    size: 2,
                    //staffComments: unit.name.toUpperCase(),
                    //additionalInformation: "D+" + pos.day,
                    //infoColor: (sidc[1] == 'H') ? '#FF0000' : '#0000FF',
                  }).asSVG()}}
                />)
            }})
          } else {
          return (<Box
            lat={latLngMap.get(locationKey(index, 0)).lat}
            lng={latLngMap.get(locationKey(index, 0)).lng}
            draggable={true}
            onDragEnd={dragEnd(locationKey(index, 0), false)}
            sx={{
              position: "relative",
              transform: "translate(-22px, -50%)"
              //transform: "translate(-22px, -28px)"
            }}
            dangerouslySetInnerHTML={{ __html: new ms.Symbol(sidc, {
              size: 20,
              staffComments: unit.name.toUpperCase(),
              //additionalInformation: sidc,
              infoColor: (sidc[1] == 'H') ? '#FF0000' : '#0000FF',
            }).asSVG()}}
          />)}
        })}

        {map.infrastructure && map.infrastructure.map((unit, index) => {
          return (
          <Box 
            lat={unit.lat} 
            lng={unit.lng} 
            display={'flex'}
            sx={{ flexDirection: 'column' }}
            alignItems={'center'}
          >
            <Box>
              <Typography variant="caption2" maxWidth={250} minWidth={150} color={'#000000'}>{unit.name}</Typography>
            </Box>
            <PlaceIcon style={{ color: '#000000' }} />
          </Box>)})}

        {map.phase_lines && map.phase_lines.map((phase_line, index) => {
          return (
          <Box 
            lat={phase_line.west.lat} 
            lng={phase_line.west.lng} 
            display={'flex'}
            sx={{ flexDirection: 'column' }}
            alignItems={'center'}
          >
            <Typography variant="caption2" color={'#000000'}><b>{phase_line.name}</b></Typography> 
          </Box>)})}

        {map.phase_lines && map.phase_lines.map((phase_line, index) => {
          return (
          <Box 
            lat={phase_line.east.lat} 
            lng={phase_line.east.lng} 
            // display={'flex'}
            // sx={{ flexDirection: 'column' }}
            // alignItems={'center'}
          >
            <Typography variant="caption2" color={'#000000'}><b>{phase_line.name}</b></Typography> 
          </Box>)})}
        
      </GoogleMap>
    </div>
  );
}