import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { useLocation, useHistory } from 'react-router-dom';

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';

import { useAppContext } from "../../lib/contextLib";

import { LoadingButton } from '@mui/lab';
import Container from 'components/Container';
import PersonalForm from './Forms/PersonalForm';
import AddressForm from './Forms/AddressForm';
import PaymentForm from './Forms/PaymentForm';
import ReviewOrder from './ReviewOrder';
import ContractModal from './ContractModal';

import validationSchema from './FormModel/validationSchema';
import checkoutFormModel from './FormModel/checkoutFormModel';
import formInitialValues from './FormModel/formInitialValues';

import useStyles from './styles';

const steps = ['Personal details', 'Address', 'Payment details', 'Review'];
const { formId, formField } = checkoutFormModel;

export default function CheckoutPage() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const { user, setUser } = useAppContext();
  const location = useLocation();
  const { amount, asset } = location.state;
  const [contract, setContract] = useState(null);
  const [contractModalOpen, setContractModalOpen] = useState(false);
  // TODO: Only take the attributes from formInitialValues
  const [savedValues, setSavedValues] = useState(user.investorProfile || formInitialValues);
  
  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <PersonalForm formField={formField} />;
      case 1:
        return <AddressForm formField={formField} />;
      case 2:
        return <PaymentForm />;
      case 3:
        return <ReviewOrder />;
      default:
        return <div>Not Found</div>;
    }
  }

  async function createContract(values) {
    const result = await API.post("contracts", `/contracts`, {
      body: {
        redirectUrl: `${window.location.protocol}//${window.location.host}/checkout/submit`,
        amount: parseInt(amount),
        investorProfile: { 
          ...values, 
          email: user.email, 
        },
        assetProps: { 
          assetId: asset.assetId, 
          address: asset.address, 
        },
      }
    });

    setContract(result);
  }
  
  async function _handleSubmit(values, actions) {
    setIsLoading(true);
    
    if (activeStep === 1 
      && JSON.stringify(values) !== JSON.stringify(savedValues)) { // Customer details were changed
      
      setSavedValues(values);
      setContract(null);
      const result = await API.put("accounts", `/account`, {
        body: {
          investorProfile: { 
            ...values, 
            email: user.email, 
          } 
        }
      });
      
      user.investorProfile = result.investorProfile;
      setUser(user);
      //alert(JSON.stringify(result, null, 2));
      // TODO: delete old contract and investor account in backend
      // TODO: If Kyc/AML check fails, block the user
    }
    
    if (isLastStep) {
      if (!contract) {
        createContract(values);
      }
      setContractModalOpen(true);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }

    setIsLoading(false);
  }

  function _handleBack() {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    } else {
      history.goBack();  
    }
  }

  return (
    <Container>
      <ContractModal 
        contract={contract} 
        contractModalOpen={contractModalOpen}
        setContractModalOpen={setContractModalOpen} />
      <React.Fragment>
        {activeStep !== steps.length ? (
          <Box marginY={{ sm: 6 }}>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  {steps[activeStep] === label ? 
                  <StepLabel><Typography><b>{label}</b></Typography></StepLabel> :
                  <StepLabel>{label}</StepLabel>} 
                </Step>
              ))}
            </Stepper>
          </Box>
        ) : (<></>)}
        <React.Fragment>
          <Formik
            initialValues={savedValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            <Form id={formId}>
              <Box 
                minHeight = {'60vh'} 
                display='flex' 
                flexDirection={'column'} 
                justifyContent='space-between'
                paddingLeft={{ xs: 2 }}
              >
                {_renderStepContent(activeStep)}
                <Box 
                  marginY={2} 
                  display='flex' 
                  flexDirection={'row'} 
                  justifyContent={{ xs: 'center', md: 'flex-end' }}
                >
                  <Box marginRight={1}>
                    <Button 
                      variant='outlined' 
                      onClick={_handleBack} 
                      size="medium"
                    >
                      Go Back
                    </Button>
                  </Box>
              
                  <LoadingButton
                    size="large"
                    loading={isLoading}
                    disabled={(activeStep === 2 && !user.isBankAuthenticated)}
                    type='submit'
                    variant='contained'
                    color='primary'
                  >
                    {isLastStep ? 'Place Order' : 'Next'}
                  </LoadingButton>
                </Box>
              </Box>
            </Form>
          </Formik>
        </React.Fragment>
      </React.Fragment>
    </Container>
  );
}
