import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { AppContext } from './lib/contextLib';
import { onError } from './lib/errorLib';
import Routes from './Routes';
import TagManager from 'react-gtm-module';
import RouteChangeTracker from './components/RouteChangeTracker';
import Page from './components/Page';
import JoinUs from 'components/JoinUs';
import ScrollToTop from './ScrollToTop';

import 'aos/dist/aos.css';

import { 
  Box, IconButton, Typography, Menu, 
  Button, Tooltip, MenuItem 
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import Logo from './resources/logo.svg'; 
import Main from './layouts/Main';

function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [user, setUser] = useState(null);
  const [docs, setDocs] = useState([]);
  const [question, setQuestion] = useState("");
  const [team, setTeam] = useState("ama");
  const [refCode, setRefCode] = useState(null);
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [session, setSession] = useState([]);
  const [sessionId, setSessionId] = useState(crypto.randomUUID()) 
  const [messages, setMessages] = useState([]);
  const [navState, setNavState] = useState('home')
  const [stateMap, setStateMap] = React.useState(null)


  //const query = useQuery();
  useEffect(() => {
    async function onLoad() {
      
      try {
        await Auth.currentSession();
        const user = API.get('accounts', `/account`);
        
        setUser((await user));
      }
      catch(e) {
        if (e !== 'No current user') {
          onError(e);
        }
      }
      
      setIsAuthenticating(false);
      
    }

    onLoad();
    // Tag for ReAmaze
    //TagManager.initialize({ gtmId: 'GTM-K9KD4RT' });
  }, []);

  async function handleLogout() {
    await Auth.signOut();
    setUser(null);
    history.push('/login');
    handleCloseUserMenu();
  }

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElLearnings, setAnchorElLearnings] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenLearningsMenu = (event) => {
    setAnchorElLearnings(event.currentTarget);
  };

  const handleCloseLearningsMenu = () => {
    setAnchorElLearnings(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // TODO: For some reason just reloading <Blog> is throwinfg error.
  // As a temporary fix refreshing the whole page.
  const reloadBlog = () => {
    window.location.href = `${window.location.protocol}//${window.location.host}/blog`;
  };

  // similar issue with faq
  const reloadFaqs = () => {
    window.location.href = `${window.location.protocol}//${window.location.host}/faq`;
  };

  function logo(sx) {
    return (
      <Link to='/'>
        <Box
          component='img'
          sx={sx}
          alt='Unwind'
          src={Logo}
        />
      </Link>
    );
  }

  function learnings(sx) {
    return (
      <Box sx={{ ...sx, flexGrow: 1 }}>
        <Tooltip title='Open learnings'>
          <Button
            style={{
              border:'none',
              outline:'none',
            }}
            disableElevation={true} 
            variant='contained'
            onClick={handleOpenLearningsMenu} 
            sx={{ p: 0 }}>
            Learn
          </Button>
        </Tooltip>
        <Menu
          sx={{ mt: '10px' }}
          anchorEl={anchorElLearnings}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElLearnings)}
          onClose={handleCloseLearningsMenu}
        >  
          <MenuItem 
            component={Link} to='#'
            onClick={reloadBlog}>
            <Typography textAlign='center'>Blog</Typography>
          </MenuItem>
          <MenuItem 
            component={Link} to='#'
            onClick={reloadFaqs}>
            <Typography textAlign='center'>FAQ</Typography>
          </MenuItem>
        </Menu>
      </Box>
    );  
  }

  function unauthMenu() { 
    return (
      <>
        { logo({ height: 75, width: 180, mr: 2 }) }
        { learnings() }   
        {/* <Button
          component={Link} to='/login'  
          onClick={handleCloseNavMenu}
          sx={{ my: 2, color: 'white'}}
        >
          Sign In
        </Button>
        <Button
          component={Link} to='/signup'  
          onClick={handleCloseNavMenu}
          sx={{ my: 2, color: 'white', borderRadius: 1, borderColor: 'text.primary', border: 1 }}
        >
          Sign Up
        </Button> */}
      </>
    );
  }

  function authMenu() {
    return (
      <>
        { logo({ height: 75, width: 180, mr: 2, display: { xs: 'none', md: 'flex' } }) }
        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
          <Button
            component={Link} to='/listings/ec3de31c-a4fe-4ffe-942e-c037a384ee28'  
            onClick={handleCloseNavMenu}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Invest
          </Button>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size='large'
            onClick={handleOpenNavMenu}
            color='inherit'
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem 
              component={Link} to='/listings/ec3de31c-a4fe-4ffe-942e-c037a384ee28'
              onClick={handleCloseNavMenu}>
              <Typography textAlign='center'>Listings</Typography>
            </MenuItem>
            <MenuItem 
              component={Link} to='#'
              onClick={reloadBlog}>
              <Typography textAlign='center'>Blog</Typography>
            </MenuItem>
            <MenuItem 
              component={Link} to='#'
              onClick={reloadFaqs}>
              <Typography textAlign='center'>FAQ</Typography>
            </MenuItem>
          </Menu>
        </Box>
        { logo({height: 33, width: 90, flexGrow: 1, display: { xs: 'flex', md: 'none' } }) }
        { learnings({ display: { xs: 'none', md: 'flex' } }) }    
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title='Open account'>
            <Button
              style={{
                border:'none',
                outline:'none'
              }}
              disableElevation={true} 
              variant='contained'
              onClick={handleOpenUserMenu} 
              sx={{ p: 0 }}>
              { user.firstName }
            </Button>
          </Tooltip>
          <Menu
            sx={{ mt: '10px' }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >  
            <MenuItem 
              component={Link} to='/settings'
              onClick={handleCloseUserMenu}>
              <Typography textAlign='center'>Account</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Typography textAlign='center'>Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </>
    );
  }


  return (
    !isAuthenticating && (
    <Page>
      <BrowserRouter>
        <ErrorBoundary>
          <AppContext.Provider value={{ 
            user, setUser, refCode, setRefCode, docs, setDocs,
            joinModalOpen, setJoinModalOpen,
            openSidebar, setOpenSidebar, sessionId, setSessionId,
            question, setQuestion, team, setTeam, session, setSession, messages, setMessages, stateMap, setStateMap, navState, setNavState }}> 
            <JoinUs 
              joinModalOpen={joinModalOpen}
              setJoinModalOpen={setJoinModalOpen}
              setUser={setUser}
            />
            <Main>
              <ScrollToTop>
                <Routes />
              </ScrollToTop>
            </Main>
            <RouteChangeTracker />
          </AppContext.Provider>
        </ErrorBoundary>
      </BrowserRouter>
    </Page>
    )
  );
}

export default App;
