import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import { onError, cleanupErrorMessage } from "../lib/errorLib";
import { useAppContext } from "../lib/contextLib";
import { InputField, PasswordField } from "../components/FormFields";
import Requirements from "../components/Requirements/Requirements";

import { 
  CssBaseline, Typography, Container, Stack, Button, Box, Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";


export default function ResetPassword() {
  const history = useHistory();
  const { setUser } = useAppContext();
  
  const [isLoading, setIsLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const [passwordLength, setPasswordLength] = useState(0);
  const [long, longEnough] = useState(false);
  const [number, hasNumber] = useState(false);
  const [special, hasSpecial] = useState(false);
  const [upperCase, hasUpperCase] = useState(false);
  const [lowerCase, hasLowerCase] = useState(false);
  
  async function handleSendCodeClick(values, {setErrors}) {
    setIsLoading(true);

    try {
      await Auth.forgotPassword(values.email);
      setCodeSent(true);
    } catch (e) {
      setErrors({ 'email': 'User does not exist.' });  
      onError(e);
    }

    setIsLoading(false);    
  }

  async function handleConfirmClick(values, {setErrors}) {
    if (!long || !number || !special || !upperCase || !lowerCase) {
      setErrors({ 'password': "Your password doesn't meet the requirements." });
      return;
    }

    setIsLoading(true);
    try {
      try {
        await Auth.forgotPasswordSubmit(
          values.email,
          values.confirmationCode,
          values.password
        );
      } catch (e) {
        if (e.name.includes("Password")) {
          setErrors({ 'password': cleanupErrorMessage(e.message) });  
        } else if (e.name.includes("Code")) {
          setErrors({ 'confirmationCode': cleanupErrorMessage(e.message) });  
        } else {
          setErrors({ 'email': cleanupErrorMessage(e.message) });
        } 
        onError(e);
        return;
      }

      await Auth.signIn(values.email, values.password);
      const userAsync = API.get("accounts", `/account`);
      setUser((await userAsync));
      
    } catch (e) {
      onError(e);
    } finally {
      setIsLoading(false);
    }
  }
 
  function renderRequestConfirmationForm() {
    return (
      <>
        <Box display='flex' justifyContent={'flex-start'} marginBottom={2}> 
          <Typography variant="h4"  sx={{ fontWeight: 600 }}>
            Reset Password
          </Typography>
        </Box>
        <Typography>
            Enter your email address below to receive the password reset code
          </Typography>
        <Formik 
          initialValues={{ email: "" }} 
          onSubmit={handleSendCodeClick}
        >
        {({
          values, 
        }) => (
          <Form>
            <InputField
              required
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              value={values.email}
              autoFocus
              sx={{ mt: 4 }}
            />

            <Box
              display='flex'
              justifyContent={'flex-end'}
              sx={{ mt: 4 }}     
            >
              <Button 
                size="medium" 
                variant="outlined"
                onClick={() => history.goBack()}
              >
                Go Back
              </Button>
              
              <Box marginLeft={2}>
                <LoadingButton
                  size="large"
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                >
                  Request Code
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
        </Formik>  
      </>      
    );
  }

  function renderPasswordResetForm() {
    return(
      <>
        <Box marginBottom={{xs: 2, sm: 3}}>
          <Typography variant="h4"  sx={{ fontWeight: 600 }}>
            Reset Password
          </Typography>
        </Box>
        <Formik
          initialValues={{ email: "", password: "", confirmationCode: "" }} 
          onSubmit={handleConfirmClick}
          validate={values => {
            if (values.password) {
              setPasswordLength(values.password.length);
              values.password.length < 8 ? longEnough(false) : longEnough(true);
              !/\d/.test(values.password) ? hasNumber(false) : hasNumber(true);
              !/[A-Z]/.test(values.password) ? hasUpperCase(false) : hasUpperCase(true);
              !/[a-z]/.test(values.password) ? hasLowerCase(false) : hasLowerCase(true);
              !/[_!@#$%^&*.,?]/.test(values.password) ? hasSpecial(false) : hasSpecial(true);
            }
          }}
        >
        {({
          values, 
        }) => (
          <Form>
            <Grid container spacing={{xs: 2, sm: 3}}>
              <Grid item xs={12}>
                <InputField
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  required
                  fullWidth
                  type="numeric"
                  label="Confirmation Code"
                  name="confirmationCode"
                  value={values.confirmationCode}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  name="password"
                  label="New Password"
                  autoComplete="off"
                  value={values.password}
                />
              </Grid>
            </Grid>
            {passwordLength > 0 
              && !(long && number && special && upperCase && lowerCase) ? 
              <Requirements long={long} number={number} special={special} 
                upperCase={upperCase} lowerCase={lowerCase} /> 
              : <></>}

            <Box
              display='flex'
              justifyContent={'flex-end'}
              sx={{ mt: 4 }}     
            >
              <Button 
                size="medium" 
                variant="outlined"
                onClick={() => history.goBack()}
              >
                Go Back
              </Button>
              
              <Box marginLeft={2}>
                <LoadingButton
                  size="large"
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                >
                  Reset Password
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
        </Formik>
      </> 
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Stack
        sx={{
          marginTop: {xs: 2, sm: 5},
          alignItems: 'left',
        }}
      >
        {!codeSent ? renderRequestConfirmationForm() : renderPasswordResetForm()}
      </Stack>
    </Container>
  );
}
