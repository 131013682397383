/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from '../../lib/contextLib';
import Subscribe from 'components/Subscribe';
import useMediaQuery from '@mui/material/useMediaQuery';

import Link from '@mui/material/Link';
import LocationOnIcon from '@mui/icons-material/LocationOn';


import Container from 'components/Container';

const Newsletter = () => {
  const theme = useTheme();
  const { setJoinModalOpen } = useAppContext();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Container paddingY={10}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          data-aos-duration={1000}
          data-aos={'zoom'}
          data-aos-offset={100}
        >
          <Box marginBottom={6}>
            <Typography
              marginBottom={2}
              variant="h5"
              align={'center'}
              gutterBottom
              sx={{
                fontWeight: 600,
                color: theme.palette.common.white,
              }}
            >
              Stay Updated
            </Typography>
            <Typography
              variant="h6"
              align={'center'}
              color="text.secondary"
            >
              Sign up to learn more about Pytho product updates, company news, and more.
            </Typography>
          </Box>
          <Subscribe color='white' />
        </Box>
        </Container>
        <Grid container paddingX={{ xs: 2, md: 10 }}>
        {/* <Grid item xs={12} marginBottom={6}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='top'
            width={1}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box
              display='flex'
              alignItems={{ xs: 'center', md: 'flex-start' }} 
              flexDirection='column'
              component='a'
              href='/'
              title='Unwind'
              height={1}
              width={{ xs: 1, md: 80 }}
              marginBottom={{ xs: 4, md: 0 }}
            >
              <Box
                component='img'
                src={LogoBlack}
                height={1}
                width={{ xs: 80, md: 1 }}
              />
            </Box>
            <Box
              display='flex' 
              flexDirection='column'
              alignItems={{ xs: 'center', md: 'flex-start' }} 
              marginBottom={{ xs: 2, md: 0 }}
            >
              <Box marginBottom={1}>
                <Link
                  underline='none'
                  component='a'
                  href='/listings'
                  color='text.primary'
                  variant='subtitle2'
                >
                  Explore Listings
                </Link>
              </Box>
            </Box>
            <Box 
              display='flex' 
              flexDirection='column'
              alignItems={{ xs: 'center', md: 'flex-start' }}
              marginBottom={{ xs: 2, md: 0 }}
            >
              <Box marginBottom={1}>
                <Link
                  underline='none'
                  component='a'
                  href='/blog'
                  color='text.primary'
                  variant='subtitle2'
                >
                  Blog
                </Link>
              </Box>
              <Box marginBottom={1}>
                <Link
                  underline='none'
                  component='a'
                  href='/faq'
                  color='text.primary'
                  variant='subtitle2'
                >
                  FAQ
                </Link>
              </Box>
            </Box>
            <Box 
              display='flex' 
              flexDirection='column'
              alignItems={{ xs: 'center', md: 'flex-start' }}
              marginBottom={{ xs: 2, md: 0 }}
            >
              <Box marginBottom={1}>
                <Link
                  underline='none'
                  component='a'
                  href='https://www.linkedin.com/company/unwind-homes'
                  target='_blank'
                  color='text.primary'
                  variant='subtitle2'
                >
                  Careers
                </Link>
              </Box>
              <Box marginBottom={1}>
                <Link
                  underline='none'
                  component='a'
                  href='/privacy-policy'
                  color='text.primary'
                  variant='subtitle2'
                >
                  Privacy Policy
                </Link>
              </Box>
              <Box marginBottom={1}>
                <Link
                  underline='none'
                  component='a'
                  href='/terms-and-conditions'
                  color='text.primary'
                  variant='subtitle2'
                >
                  Terms of Service
                </Link>
              </Box>
            </Box>   
            <Box 
              display='flex' 
              flexDirection='column'
              alignItems={{ xs: 'center', md: 'flex-start' }}
            >
              <Box 
                display='flex' 
                flexDirection='row'
                marginBottom={{ xs: 4, md: 0 }}
              >
                <Box marginRight={2}>
                  <Link
                    underline='none'
                    component='a'
                    href='https://www.facebook.com/unwindhomes'
                    target='_blank'
                    color='text.primary'
                    variant='subtitle2'
                  >
                    <FacebookIcon />
                  </Link>
                </Box>
                <Box marginRight={2}>
                  <Link
                    underline='none'
                    component='a'
                    href='https://www.instagram.com/unwind_homes'
                    target='_blank'
                    color='text.primary'
                    variant='subtitle2'
                  >
                    <InstagramIcon />
                  </Link>
                </Box>
                <Box marginRight={2}>
                  <Link
                    underline='none'
                    component='a'
                    href='https://www.linkedin.com/company/unwind-homes'
                    target='_blank'
                    color='text.primary'
                    variant='subtitle2'
                  >
                    <LinkedInIcon />
                  </Link>
                </Box>
                <Box>
                  <Link
                    underline='none'
                    component='a'
                    href='mailto: support@unwindhomes.com'
                    color='text.primary'
                    variant='subtitle2'
                  >
                    <EmailIcon />
                  </Link>
                </Box>
              </Box>
            </Box>  
          </Box>  
        </Grid> */}
        
        <Grid item xs={12}>
          {/* <Typography
            align='justify'
            variant='caption'
            color='text.secondary'
            component='p'
          >
            Unwind Homes, LLC (the "Company") intends to sponsor a securities offering  with respect to this property pursuant to Regulation A under the Securities Act of 1933. The Company is now "testing the waters" to gauge market demand and it is under no obligation to  make an offering under Regulation A. No money or other consideration is being solicited in connection with the information that is now being provided, and if money is sent in response, it will not be accepted. No offer to buy our securities can be accepted and no part of the purchase price can be received until an offering statement on Form 1-A has been qualified by the Securities and Exchange Commission, and any such offer may be withdrawn or revoked, without obligation or commitment of any kind, at any time before notice of its acceptance given after the Form 1-A qualification date. A person's indication of interest involves no obligation or commitment of any kind. 
          </Typography> */}
          {!isSm ? (
          <Box  
            display='flex'
            flexDirection={'column'} 
            alignItems={'center'} 
            justifyContent={'center'}
          >
            <Typography 
              variant='subtitle2'
              color='text.secondary'
            >
              <LocationOnIcon />  
            </Typography>
            <Typography
              variant='subtitle2'
              color='text.secondary'
              gutterBottom
            >
              San Francisco, California
            </Typography>
            
            <Typography
              variant='subtitle2'
              color='text.secondary'
              gutterBottom
            >
              {'© '}
              {new Date().getFullYear()}
              {' '}
              <Link underline='none' color='inherit' href='/'>
                Pytho AI 
              </Link>
            </Typography>
          </Box>
          ) : (
          <Box  
            display='flex'
            flexDirection={'row'} 
            alignItems={'center'} 
            justifyContent={'center'}
          >
            <Typography
              variant='subtitle2'
              color='text.secondary'
              gutterBottom
            >
              {'© '}
              {new Date().getFullYear()}
              {' '}
              <Link underline='none' color='inherit' href='/'>
                Pytho AI
              </Link>
            </Typography>
            <Typography 
              variant='subtitle2'
              paddingX={1} 
              color='text.secondary'
            >
              <LocationOnIcon />  
            </Typography>
            <Typography
              variant='subtitle2'
              color='text.secondary'
              gutterBottom
            >
              San Francisco, California
            </Typography>
          </Box>
          )}
        </Grid>
      </Grid>
      
    </Box>
  );
};

export default Newsletter;
