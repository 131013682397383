import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

const SingleNavItem = ({ title, id, path, colorInvert = false, reload = false }) => {
  const theme = useTheme();
  const history = useHistory();

  
  const handleClick = () => {
    if (reload) {
      window.location.href = `${window.location.protocol}//${window.location.host}${path}`;
    } else {
      history.push(path);
    } 
  };

  const [activeLink, setActiveLink] = useState('');
  // useEffect(() => {
  //   setActiveLink(window && window.location ? window.location.pathname : '');    
  // }, []);

  const hasActiveLink = () => activeLink.indexOf(path) > -1;
  const linkColor = colorInvert ? 'common.white' : 'text.primary';

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      aria-describedby={id}
      sx={{ cursor: 'pointer' }}
      onClick={(e) => handleClick(e, id)}
    >
      <Typography
        fontWeight={hasActiveLink() ? 700 : 400}
        color={linkColor}
      >
        {title}
      </Typography>
    </Box>
  );
};

SingleNavItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  colorInvert: PropTypes.bool,
};

export default SingleNavItem;
