import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useQuery } from "lib/hooksLib";
import { useAppContext } from "lib/contextLib";
import { useLocation } from 'react-router-dom';

import { Topbar, Sidebar, Footer, FullScreenVideoBackground } from './components';

import pages from '../navigation';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: "100%",
    // transform: 'translate(100%, -50%)',
    // textAlign: 'center',
    // color: '#ffffff', // You can set your desired text color
  },
  heading: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    fontSize: '1.5rem',
  },
}));

const Main = ({ children, colorInvert = false, bgcolor = 'transparent' }) => {
  const location = useLocation();

  // Check if the current path is the home page ('/')
  const isHomePage = location.pathname === '/';

  const theme = useTheme();
  const classes = useStyles();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  
  const { user, openSidebar, setOpenSidebar } = useAppContext();
  
  const query = useQuery();
  const { setRefCode } = useAppContext();
  
  
  useEffect(() => {
    const refCode = query.get("r");
    setRefCode(refCode);
  }, []);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  //const open = isMd ? false : openSidebar;
  const open = openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <>
    {/* {isHomePage && <FullScreenVideoBackground videoSource={`${process.env.PUBLIC_URL}/videos/home.mov`} />} */}
    <Box className={classes.textContainer}>
      <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
        }}
        elevation={trigger ? 1 : 0}
      >
        <Box paddingX={2} marginTop={2} marginBottom={2}>
          <Topbar
            onSidebarOpen={handleSidebarOpen}
            pages={pages}
            colorInvert={trigger ? false : colorInvert}
          />
        </Box>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant={user && isMd ? "permanent" : "temporary"}
        pages={pages}
      />
      <main>
        <Box minHeight={'90vh'}>
          {children}
        </Box>
      </main>
      {/* <Box paddingX={2} marginBottom={2}>
        <Footer />
      </Box> */}
    </Box>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Main;