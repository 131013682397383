import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { querystring } from "lib/urlLib";
import { onError, cleanupErrorMessage } from "../lib/errorLib";
import { InputField, PasswordField } from "../components/FormFields";
import { useHistory } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { 
  CssBaseline, Stack, Typography, Container, Box, Button, Grid, 
} from '@mui/material';
import { Link } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Formik, Form } from "formik";

export default function Login() {
  const theme = useTheme();
  const { setMode } = theme;
  const history = useHistory();
  const { setLinkToken, setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const redirect = querystring("redirect");

  useEffect(() => {
    setMode('dark');
  }, []);
  
  async function handleSubmit(values, {setErrors}) {
    setIsLoading(true);

    try {
      try {
        await Auth.signIn(values.email, values.password);
      } catch (e) {
        if (e.name === 'UserNotFoundException') {
          setErrors({ 'email': cleanupErrorMessage(e.message) });  
        } else {
          setErrors({ 'password': cleanupErrorMessage(e.message) });
        }
        onError(e);
        return;
      }

      //const result = generateLinkToken();
      const user = API.get("accounts", `/account`);
      
      //setLinkToken((await result).link_token);
      setUser((await user));          
    } catch (e) {
      if (e.response.data.error.includes('User')) {
        setErrors({ 'email': e.response.data.error });
      } else {
        setErrors({ 'password': e.response.data.error });
      }
      
     
      onError(e);
    } finally {
      setIsLoading(false);
    }
  }

  function generateLinkToken() {
    return API.post("payments", `/payment/create_link_token`);
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Stack
        sx={{
          marginTop: 8,
          alignItems: 'left',
        }}
      >
        <Box display='flex' justifyContent={'flex-start'} marginBottom={10}> 
          <Typography variant="h2">
            Welcome to Pytho
          </Typography>
        </Box>
        {/* <Box display='flex' justifyContent={'flex-start'} marginBottom={8} marginTop={5}> 
          <Typography variant="h6">
            Revolutionizing Training for the DoD and Intelligence Community
          </Typography>
        </Box> */}

        <Formik initialValues={{ email: "", password: "" }} onSubmit={handleSubmit}>
          <Form>
            <Grid container spacing={{xs: 2, sm: 3}}>
              <Grid item xs={12}>
                <InputField
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
              </Grid>  
              <Grid item xs={12}>  
                <PasswordField
                  name="password"
                  label="Password"            
                  autoComplete="current-password"
                />
              </Grid>
            </Grid>
            {/* <Box sx={{ mt: 4 }} display='flex' alignItems={'center'} justifyContent='space-between'> */}
              <Typography variant="body2" color={'text.secondary'} component={Link} to={`/login/reset${redirect ? '?redirect=' + redirect : ''}`}>
                Forgot password?  
              </Typography> 
              <Box
                display='flex'
                justifyContent={'space-between'}
                sx={{ mt: 4 }}     
              >
                {/* <Button 
                  size="medium" 
                  variant="outlined"
                  onClick={() => history.goBack()}
                >
                  Go Back
                </Button> */}
                <Box 
                  display='flex' 
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                > 
                  <Typography color={'text.secondary'}>
                    Don't have an account? <Typography 
                      color={'text.secondary'}
                      component={Link} to={`/signup${redirect ? '?redirect=' + redirect : ''}`}> 
                      Sign Up
                    </Typography>
                  </Typography>
                </Box> 
                
                <Box marginLeft={2}>
                  <LoadingButton
                    size="large"
                    loading={isLoading}
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowRightAltIcon />}
                  >
                    Continue
                  </LoadingButton>
                </Box>
              </Box>

            {/* </Box> */}
          </Form>
        </Formik>
      </Stack>
    </Container>
  );
}
